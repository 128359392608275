import { Box } from 'braid-design-system';
import { useState, type ReactNode, type MouseEvent } from 'react';

import { Overlay } from 'src/components/atoms/Overlay/Overlay';

import * as styles from './Menu.css';

interface TriggerProps {
  onClick: (event: MouseEvent) => void;
}

interface BodyProps {
  closeMenu: () => void;
}

export interface Props {
  width?: keyof typeof styles.width | 'content';
  trigger: (props: TriggerProps, isOpen: boolean) => ReactNode;
  body: (props: BodyProps) => ReactNode;
}

export const Menu = ({ width = 'content', trigger, body }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => setIsOpen(!isOpen);

  const triggerProps = {
    onClick: (event: MouseEvent) => {
      event.stopPropagation();
      event.preventDefault();
      toggleMenu();
    },
  };

  const bodyProps = {
    closeMenu: () => {
      setIsOpen(false);
    },
  };

  return (
    <Box
      style={{
        position: 'relative',
      }}
    >
      {trigger(triggerProps, isOpen)}
      {isOpen && (
        <Box
          role="menu"
          position="absolute"
          zIndex="dropdown"
          marginTop="small"
          background="surface"
          transition="fast"
          borderRadius="large"
          right={0}
          opacity={!isOpen ? 0 : undefined}
          overflow="hidden"
          className={[width !== 'content' && styles.width[width]]}
        >
          {body(bodyProps)}
          <Overlay
            boxShadow="borderNeutralLight"
            borderRadius="large"
            visible
          />
        </Box>
      )}
      {isOpen ? (
        <Box
          id="dropdown-backdrop"
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
            setIsOpen(false);
          }}
          position="fixed"
          zIndex="dropdownBackdrop"
          top={0}
          left={0}
          style={{
            width: '100vw',
            height: '100vh',
          }}
        />
      ) : null}
    </Box>
  );
};
