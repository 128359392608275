import { Stack, Text } from 'braid-design-system';

import EmptyLogoUrl from 'src/assets/SearchNew.png';
import { TableBodyState } from 'src/components/molecules/TableBodyState/TableBodyState';

export const ErrorState = () => (
  <TableBodyState
    SVGComponent={
      <img src={EmptyLogoUrl} alt="No data" style={{ width: '136px' }} />
    }
    title="No matching search results"
    subtitle={
      <Stack space="none">
        <Text
          tone="secondary"
          size="xsmall"
          align="center"
        >{`We couldn't find anything that matched your search.`}</Text>
        <Text tone="secondary" size="xsmall" align="center">
          Please check and enter again.
        </Text>
      </Stack>
    }
  />
);
