import type { ClientContext } from './types/types';

export enum FeatureFlags {
  TEST = 'test',
  SMARTHIRE_CANDIDATE = 'smartHireCandidate',
  HIRER_WITH_MULTI_COMPANIES = 'hirerWithMultiCompanies',
  CANDIDATE_OPEN_TO_OPPORTUNITIES = 'candidateOpenToOpportunities',
  CANDIDATE_STANDOUT = 'candidateStandout',
  DOWNLOAD_BLIND_CV = 'downloadBlindCv',
  SCHEDULE_INTERVIEW = 'scheduleInterview',
  VIEW_CANDIDATE_CONTACT = 'viewCandidateContact',
}

type Features = Record<string, boolean>;

export const features: Record<ClientContext['environment'], Features> = {
  development: {
    test: true,
    smartHireCandidate: true,
    hirerWithMultiCompanies: true,
    candidateOpenToOpportunities: true,
    candidateStandout: true,
    downloadBlindCv: true,
    scheduleInterview: true,
    viewCandidateContact: true,
  },
  staging: {
    test: false,
    smartHireCandidate: true,
    hirerWithMultiCompanies: true,
    candidateOpenToOpportunities: true,
    candidateStandout: true,
    downloadBlindCv: true,
    scheduleInterview: true,
    viewCandidateContact: true,
  },
  production: {
    test: false,
    smartHireCandidate: true,
    hirerWithMultiCompanies: false,
    candidateOpenToOpportunities: false,
    candidateStandout: false,
    downloadBlindCv: false,
    scheduleInterview: false,
    viewCandidateContact: false,
  },
};
