import type { ClientContext } from 'src/types/types';

interface Config {
  appUrl: string;
  apiEndpoint: string;
}

export const config: Record<ClientContext['environment'], Config> = {
  development: {
    appUrl: 'http://localhost:22000',
    apiEndpoint: 'http://localhost:57217',
  },
  staging: {
    appUrl: 'https://d17s4g1p7pjefx.cloudfront.net',
    apiEndpoint: 'https://smart-hire-auth-proxy.staging.cloud.seek.com.au',
  },
  production: {
    appUrl: 'https://www.smarthire.asia',
    apiEndpoint: 'https://smart-hire-auth-proxy.cloud.seek.com.au',
  },
};
