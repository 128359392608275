import {
  Box,
  ContentBlock,
  Heading,
  List,
  Stack,
  Text,
} from 'braid-design-system';

import { NotFoundIcon } from 'src/components/atoms/NotFoundIcon/NotFoundIcon';

export const NotFound = () => (
  <ContentBlock width="small">
    <Box paddingY={['none', 'xxlarge']}>
      <Box
        background="surface"
        paddingY={['xxlarge', 'xlarge']}
        paddingX="gutter"
      >
        <Stack space={['large', 'xlarge']} align="center">
          <NotFoundIcon />
          <Box>
            <Stack space="gutter" align="center">
              <Heading level="3">We couldn’t find that page</Heading>
              <Text tone="secondary">
                But maybe we can help you find what you need.
              </Text>
              <List type="number" tone="secondary" space="medium">
                <Text tone="secondary">Check that the URL is correct.</Text>
                <Text tone="secondary">
                  Try going back to the previous page.
                </Text>
                <Text tone="secondary">
                  Please reach out to the SmartHire Ops Team at
                  customerservice@smarthire.asia for more assistance.
                </Text>
              </List>
            </Stack>
          </Box>
        </Stack>
      </Box>
    </Box>
  </ContentBlock>
);
