import { type ReactNode, createContext, useContext, useEffect } from 'react';
import {
  type NavigateFunction,
  useNavigate as useRouterNavigate,
} from 'react-router-dom';

import { navigationService } from 'src/utils/NavigationService';

const NavigationContext = createContext<NavigateFunction | null>(null);

export const NavigationProvider = ({ children }: { children: ReactNode }) => {
  const navigate = useRouterNavigate();

  useEffect(() => {
    navigationService.setNavigator(navigate);
  }, [navigate]);

  return (
    <NavigationContext.Provider value={navigate}>
      {children}
    </NavigationContext.Provider>
  );
};

export const useNavigate = () => useContext(NavigationContext);
