import { Link as RouteLink } from 'react-router-dom';

import WhiteLogoUrl from 'src/assets/logo-white@6x.png';
import LogoUrl from 'src/assets/logo.png';

interface props {
  color: 'light' | 'dark';
}

export const Logo = ({ color }: props) => (
  <RouteLink to="/">
    <img
      src={color === 'light' ? WhiteLogoUrl : LogoUrl}
      alt="Smart Hire logo"
      width="112"
    />
  </RouteLink>
);
