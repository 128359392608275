import type { NavigateFunction } from 'react-router-dom';

export class NavigationService {
  navigate: NavigateFunction | null;

  constructor() {
    this.navigate = null;
  }

  setNavigator(navigate: NavigateFunction) {
    this.navigate = navigate;
  }

  goToForbidden() {
    if (this.navigate) {
      this.navigate('/forbidden');
    }
  }

  goToLogin() {
    if (this.navigate) {
      this.navigate('/login');
    }
  }

  goToAccountSelection() {
    if (this.navigate) {
      this.navigate('/account-selection');
    }
  }
}

export const navigationService = new NavigationService();
