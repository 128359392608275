import { useContext } from 'react';

import { SmartHireClientContext } from 'src/contexts/SmartHireClient/SmartHireClientProvider';

export const useSmartHireClient = () => {
  const context = useContext(SmartHireClientContext);

  if (context === null) {
    throw new Error(
      `useSmartHireClient must be used within a SmartHireClientProvider`,
    );
  }

  return context;
};
