import {
  Box,
  Heading,
  PageBlock,
  Stack,
  Text,
  Inline,
} from 'braid-design-system';

import NoAccessSVG from 'src/components/atoms/NoAccess/NoAccessSVG';

export const NoAccess = () => (
  <PageBlock>
    <Box
      paddingY={{ mobile: 'xxlarge', tablet: 'xxlarge', desktop: 'xxlarge' }}
      paddingX={{ mobile: 'large', tablet: 'none', desktop: 'none' }}
    >
      <Stack space="gutter" align="center">
        <Heading level="1">
          <NoAccessSVG />
        </Heading>
      </Stack>
      <Stack space="large" align="center">
        <Heading level="2">Oops!</Heading>
        <Text align="center" size="standard" weight="regular">
          We think you do not have access to this link.
        </Text>
        <Stack space="small" align="center">
          <Text>Please reach out to the SmartHire Ops</Text>
          <Inline space="xxsmall">
            <Text>{'Team at'}</Text>
            <Text tone="promote" weight="strong">
              customerservice@smarthire.asia
            </Text>
            <Text> for</Text>
          </Inline>
          <Text>more assistance</Text>
        </Stack>
      </Stack>
    </Box>
  </PageBlock>
);
