// See https://stackoverflow.com/questions/47057649/typescript-string-dot-notation-of-nested-object
export type PathsToStringProps<T> = T extends string | number | boolean | File
  ? []
  : {
      [K in Extract<keyof T, string>]: [K, ...PathsToStringProps<T[K]>];
    }[Extract<keyof T, string>];

export type Join<T extends string[], D extends string> = T extends []
  ? never
  : T extends [infer F]
  ? F
  : T extends [infer F, ...infer R]
  ? F extends string
    ? `${F}${D}${Join<Extract<R, string[]>, D>}`
    : never
  : string;

export const formatDate = (isoDate: string | undefined): string => {
  if (!isoDate) return 'No Date Provided';

  const formatter = new Intl.DateTimeFormat('en-GB', {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  });

  return formatter.format(new Date(isoDate));
};

export const findObjectValueWithKey = <T extends Record<string, any>>(
  value: string,
  mapping: T,
): T[keyof T] => mapping[value as keyof T];
