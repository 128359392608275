import { Box, Text } from 'braid-design-system';
import { useState, type ReactNode } from 'react';

interface Props {
  tone?: 'critical';
  onClick: () => void;
  children: ReactNode;
}

export const MenuButton = ({ tone, onClick, children }: Props) => {
  const [isHighlighted, setIsHighlighted] = useState(false);

  const hoverBackground =
    tone === 'critical' ? 'criticalLight' : 'formAccentSoft';

  return (
    <Box
      style={{ margin: '0 -16px' }}
      padding="small"
      background={isHighlighted ? hoverBackground : undefined}
      onMouseEnter={() => setIsHighlighted(true)}
      onMouseLeave={() => setIsHighlighted(false)}
      onClick={onClick}
      cursor="pointer"
    >
      <Text tone={tone}>{children}</Text>
    </Box>
  );
};
