import { Box } from 'braid-design-system';
import React, { type ElementType, type ReactNode } from 'react';

export interface OverlayProps {
  component?: ElementType;
  zIndex?: any;
  background?: any;
  borderRadius?: any;
  boxShadow?: any;
  transition?: any;
  className?: string;
  children?: ReactNode;
  visible?: boolean;
  onlyVisibleForKeyboardNavigation?: boolean;
}

export const Overlay = ({
  component,
  zIndex,
  background,
  borderRadius,
  boxShadow,
  transition,
  visible = false,
  className,
  children,
}: OverlayProps) => (
  <Box
    component={component}
    position="absolute"
    zIndex={zIndex}
    pointerEvents="none"
    background={background}
    borderRadius={borderRadius}
    boxShadow={boxShadow}
    transition={transition}
    inset={0}
    opacity={!visible ? 0 : undefined}
    className={[className]}
  >
    {children}
  </Box>
);
