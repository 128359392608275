import { Box, PageBlock } from 'braid-design-system';
import type { ReactNode } from 'react';

export const PageHeader = ({
  id,
  paddingBottom,
  children,
}: {
  id?: string;
  paddingBottom?: 'none';
  children: ReactNode;
}) => (
  <Box background="surface" id={id}>
    <PageBlock>
      <Box paddingX="xsmall" paddingY="gutter" paddingBottom={paddingBottom}>
        {children}
      </Box>
    </PageBlock>
  </Box>
);
