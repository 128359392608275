import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';

import { QueryKey } from 'src/constants/queryKeys';
import { UserContext } from 'src/contexts/UserContex/UserContextProvider';
import type { Company } from 'src/types/types';

import { useSmartHireClient } from '../useSmartHireClient/useSmartHireClient';

export const useGetCompany = () => {
  const { smartHireClient } = useSmartHireClient();
  const { data: user } = useContext(UserContext);
  const loggedInUserCompanyId = user?.companyId;

  const fetchCompany = async (): Promise<Company> => {
    if (!loggedInUserCompanyId) {
      throw new Error('No logged in user company found');
    }

    const { data } = await smartHireClient.send<Company>({
      url: `/companies/${loggedInUserCompanyId}`,
    });
    if (data) {
      return data;
    }
    throw new Error(
      `Unable to fetch company details for id: ${loggedInUserCompanyId}`,
    );
  };

  return useQuery<Company, Error>({
    queryKey: [QueryKey.COMPANY, loggedInUserCompanyId],
    queryFn: fetchCompany,
    retry: 2,
    enabled: Boolean(loggedInUserCompanyId),
  });
};
