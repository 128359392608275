import type { AxiosError } from 'axios';

import { ErrorMessage } from 'src/constants/errorMessages';

export interface ErrorResponse {
  error?: {
    message?: string;
  };
}

export const isSessionInvalidError = (error: AxiosError<ErrorResponse>) =>
  error.response?.data?.error?.message === ErrorMessage.SESSION_INVALID;
