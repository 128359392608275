import {
  Box,
  Text,
  TooltipRenderer,
  IconHelp,
  Inline,
  IconChevron,
  Pagination,
} from 'braid-design-system';
import {
  useState,
  type ReactNode,
  useEffect,
  type ReactElement,
  Fragment,
} from 'react';

import { flattenObject } from 'src/utils/Object';

import { ErrorState } from './BodyState/ErrorState';
import { LoadingState } from './BodyState/LoadingState';

import { cursorPointer, table, customCellStyle } from './Table.css';
export interface TableHeading<T extends string> {
  label: string;
  key?: T;
  weight?: 'strong' | 'medium' | 'regular' | undefined;
  maxlines?: number;
  textAlign?: 'left' | 'right' | 'center';
  isDisabled?: (index: number) => boolean;
  element?: (index: number) => ReactNode;
  tooltip?: () => ReactElement;
}

export interface TableRow {
  isDisabled?: boolean;
}

interface PaginationProps {
  isEnabled: boolean;
  onPageChange: (pageNumber: number) => void;
  pageNumber?: number;
  totalRowCount: number;
  rowsPerPage: number;
  tooltip?: () => ReactElement;
}
export interface AppProps<Row> {
  id: string;
  label: string;
  headings: Array<TableHeading<string>>;
  rows: Array<Row & { isDisliked?: boolean; disabled?: boolean }>;
  isLoading?: boolean;
  isError?: boolean;
  textSize?: 'small' | 'standard' | 'large' | 'xsmall' | undefined;
  rowActions?: (index: number) => ReactNode;
  expandedRow?: (index: number) => ReactNode;
  pagination?: PaginationProps;
  rowClickHandler?: (index: number) => void;
  emptyState?: ReactNode;
}

const defaultPaginationProps = {
  isEnabled: false,
  onPageChange: () => null,
  pageNumber: 1,
  totalRowCount: 1,
  rowsPerPage: 5,
};

export const Table = <Row extends object>({
  id,
  label,
  headings,
  rows,
  isLoading = false,
  isError = false,
  textSize = 'small',
  rowActions,
  expandedRow,
  pagination = defaultPaginationProps,
  rowClickHandler,
  emptyState,
}: AppProps<Row>) => {
  const [flattenedRows, setFlattenedRows] = useState<
    Array<Record<string, any>>
  >([]);

  const [currentPage, setCurrentPage] = useState(pagination.pageNumber || 1);
  const totalPage = Math.ceil(
    pagination?.totalRowCount / pagination.rowsPerPage,
  );

  const onRowClick = (index: number) => {
    if (!expandedRow) {
      return;
    }

    const newRows = [...flattenedRows];
    newRows[index].isExpanded = !newRows[index].isExpanded;
    setFlattenedRows(newRows);
  };

  const handlePageChange =
    (pageNumber: number) =>
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      e.preventDefault();
      pagination.onPageChange(pageNumber);
      setCurrentPage(pageNumber);
    };

  useEffect(() => {
    setFlattenedRows(
      rows.map((row) => ({
        ...flattenObject(row),
        isExpanded: false,
        isDisliked: row.isDisliked,
        isDisabled: row.disabled,
      })),
    );
  }, [rows]);

  const renderState = () => {
    if (isLoading) {
      return (
        <tr>
          <td colSpan={rowActions ? headings.length + 1 : headings.length}>
            <LoadingState />
          </td>
        </tr>
      );
    }

    if (isError) {
      return (
        <tr>
          <td colSpan={rowActions ? headings.length + 1 : headings.length}>
            <ErrorState />
          </td>
        </tr>
      );
    }

    if (flattenedRows.length === 0) {
      return (
        <tr>
          <td colSpan={rowActions ? headings.length + 1 : headings.length}>
            {emptyState}
          </td>
        </tr>
      );
    }

    return flattenedRows.map((row, index) => {
      const normalizedIndex = index;
      return (
        <Fragment key={`row-${normalizedIndex}-fragment`}>
          <tr
            key={`row-${normalizedIndex}`}
            className={
              expandedRow || (!row.isDisabled && rowClickHandler)
                ? cursorPointer
                : ''
            }
            onClick={() => {
              onRowClick(normalizedIndex);
              if (rowClickHandler && !row.isDisabled) {
                rowClickHandler(normalizedIndex);
              }
            }}
            style={row.isDisabled ? { opacity: 0.4 } : {}}
          >
            {headings.map((heading) => (
              <td
                key={`cell-${normalizedIndex}-${heading.key}`}
                id={`cell-${normalizedIndex}-${heading.key}`}
                className={customCellStyle}
                style={
                  heading.isDisabled && heading.isDisabled(index)
                    ? { opacity: 0.4 }
                    : {}
                }
              >
                {heading.element ? (
                  heading.element(normalizedIndex)
                ) : (
                  <Text
                    maxLines={heading.maxlines}
                    size={textSize}
                    weight={heading.weight}
                  >
                    {heading.key && String(row[heading.key] ?? '--')}
                  </Text>
                )}
              </td>
            ))}

            {Boolean(rowActions) && (
              <td id={`cell-${normalizedIndex}-actions`}>
                {rowActions?.(normalizedIndex)}
              </td>
            )}
            {expandedRow && (
              <td>
                <Box display="flex" alignItems="center">
                  <IconChevron direction={row.isExpanded ? 'up' : 'down'} />
                </Box>
              </td>
            )}
          </tr>
          {expandedRow && row.isExpanded && (
            <tr key={`row-expanded-${normalizedIndex}`}>
              <td colSpan={rowActions ? headings.length + 1 : headings.length}>
                {expandedRow(normalizedIndex)}
              </td>
            </tr>
          )}
        </Fragment>
      );
    });
  };

  return (
    <Box background="surface" borderRadius="standard" padding="small">
      <table id={id} className={table} aria-label={label}>
        <thead>
          <tr>
            {headings.map((heading) => (
              <th
                key={heading.label}
                id={`table-header-${heading.key}`}
                style={{ textAlign: heading.textAlign }}
              >
                {heading.tooltip ? (
                  <TooltipRenderer
                    id={`tooltip-${heading.key}`}
                    placement="bottom"
                    tooltip={heading.tooltip?.()}
                  >
                    {({ triggerProps }) => (
                      <Box display="flex" justifyContent="center">
                        <Inline space="small" alignY="center">
                          <Text size={textSize} weight="strong">
                            {heading.label}
                          </Text>
                          {heading.tooltip && (
                            <Box
                              aria-label="Help"
                              {...triggerProps}
                              cursor="pointer"
                            >
                              <IconHelp />
                            </Box>
                          )}
                        </Inline>
                      </Box>
                    )}
                  </TooltipRenderer>
                ) : (
                  <Text size={textSize} weight="strong">
                    {heading.label}
                  </Text>
                )}
              </th>
            ))}
            {Boolean(rowActions) && (
              <th id="table-header-actions" aria-label="Actions" />
            )}
          </tr>
        </thead>
        <tbody style={{ width: '100%' }}>{renderState()}</tbody>
      </table>
      {!isLoading && pagination.isEnabled && totalPage > 0 && (
        <Box marginTop="medium" id="box-pagination">
          <Pagination
            label="Pagination"
            page={currentPage}
            total={totalPage}
            pageLimit={7}
            linkProps={({ page }) => ({
              href: `#${page}`,
              onClick: handlePageChange(page),
            })}
          />
        </Box>
      )}
    </Box>
  );
};
