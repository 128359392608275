interface props {
  color: 'light' | 'dark';
}

export const BetaTag = ({ color }: props) => (
  <svg
    width="47"
    height="16"
    style={{ marginLeft: 'auto' }}
    display="block"
    viewBox="0 0 47 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="47"
      height="16"
      rx="8"
      fill={color === 'light' ? 'white' : '#0D3880'}
    />
    <path
      d="M12.99 4.17C14.49 4.17 15.51 4.94 15.51 6.23C15.51 6.65 15.31 7.5 14.38 7.92C15.57 8.3 15.87 9.19 15.87 9.81C15.87 11.07 14.96 12 13.09 12H10V4.17H12.99ZM11.22 10.89H13.11C14.13 10.89 14.64 10.39 14.64 9.71C14.64 9.07 14.16 8.56 13.03 8.56H11.22V10.89ZM11.22 7.48H12.76C13.83 7.48 14.28 6.96 14.28 6.36C14.28 5.76 13.88 5.28 12.83 5.28H11.22V7.48ZM22.8538 5.28H19.0738V7.46H22.5138V8.57H19.0738V10.88H22.8938V12H17.8438V4.17H22.8538V5.28ZM24.1746 5.31V4.17H30.3746V5.31H27.8846V12H26.6546V5.31H24.1746ZM30.3438 12L33.5338 4.17H34.9838L38.1538 12H36.8738L36.1638 10.16H32.3238L31.6038 12H30.3438ZM32.7638 9.07H35.7338L34.2438 5.35L32.7638 9.07Z"
      fill={color === 'light' ? '#0D3880' : 'white'}
    />
  </svg>
);
