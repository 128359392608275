import { useState, useEffect } from 'react';

import { LocalStorageKey } from 'src/constants/localStorageKeys';

export const useCheckIsLoggedIn = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const checkIsLoggedIn = async () => {
      const accessToken = localStorage.getItem(LocalStorageKey.ACCESS_TOKEN);
      setIsLoggedIn(Boolean(accessToken));
    };

    checkIsLoggedIn();
    window.addEventListener('storage', checkIsLoggedIn);

    return () => {
      window.removeEventListener('storage', checkIsLoggedIn);
    };
  }, []);

  return isLoggedIn;
};
