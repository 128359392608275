import axios, {
  type AxiosRequestConfig,
  type Axios,
  type AxiosResponse,
  type AxiosError,
  isAxiosError,
} from 'axios';
import type { useToast } from 'braid-design-system';
import { v4 } from 'uuid';

import { config } from 'src/config';
import { ErrorMessage } from 'src/constants/errorMessages';
import { LocalStorageKey } from 'src/constants/localStorageKeys';
import type { ClientContext } from 'src/types/types';

import { navigationService } from './NavigationService';
import { isSessionInvalidError } from './errorUtils';

export interface Response<T> {
  success: boolean;
  error?: { code: number; message: string };
  data?: T;
  meta?: {
    pagination?: Pagination;
  };
}
export interface Pagination {
  totalRecords: number;
  pageNumber: number;
  pageSize: number;
  totalPages: number;
}

export class SmartHireClient {
  client: Axios;
  showToast: ReturnType<typeof useToast> | undefined;

  constructor(
    environment: ClientContext['environment'],
    showToast?: ReturnType<typeof useToast>,
  ) {
    this.client = axios.create({
      baseURL: config[environment].apiEndpoint,
    });

    this.client.interceptors.response.use(
      undefined,
      this.handleErrorResponse.bind(this),
    );
    this.client.interceptors.request.use((req) => {
      req.headers.Authorization = `Bearer ${localStorage.getItem(
        LocalStorageKey.ACCESS_TOKEN,
      )}`;
      return req;
    });

    this.showToast = showToast;
  }

  setAuthHeader(token: string) {
    this.client.defaults.headers.common = {
      Authorization: `Bearer ${token}`,
    };
  }

  clearAuth() {
    // remove all tokens and let ProtectedRoute handle logout
    localStorage.removeItem(LocalStorageKey.ACCESS_TOKEN);
    localStorage.removeItem(LocalStorageKey.REFRESH_TOKEN);
    dispatchEvent(new Event('storage'));

    this.client.defaults.headers.common = {};
  }

  async refreshToken(): Promise<void> {
    try {
      const refreshToken = localStorage.getItem(LocalStorageKey.REFRESH_TOKEN);
      if (!refreshToken) {
        throw new Error('Missing refresh token');
      }

      const { data } = await this.client.get<Response<{ accessToken: string }>>(
        '/hirers/refresh',
        {
          params: {
            refreshToken,
          },
          headers: {
            'x-request-id': v4(),
          },
        },
      );

      if (data?.data?.accessToken) {
        localStorage.setItem(
          LocalStorageKey.ACCESS_TOKEN,
          data.data.accessToken,
        );
        this.setAuthHeader(data.data.accessToken);

        return;
      }

      throw new Error('Missing access token from response');
    } catch (error) {
      this.clearAuth();

      throw error;
    }
  }

  async getLoginLink(email: string, recaptchaToken: string): Promise<void> {
    await this.client.get<Response<undefined>>('/hirers/link', {
      params: {
        email,
        recaptchaToken,
      },
      headers: {
        'x-request-id': v4(),
      },
    });
  }

  async handleErrorResponse(error: AxiosError<Response<undefined>>) {
    if (
      error.response &&
      error.response.status === 403 &&
      error.response.data.error?.message === 'Hirer has not selected a company'
    ) {
      navigationService.goToAccountSelection();
      return Promise.reject(error);
    }

    if (error.response && error.response.status === 403) {
      navigationService.goToForbidden();
      return Promise.reject(error);
    }

    if (
      isAxiosError(error) &&
      error.response?.status === 401 &&
      isSessionInvalidError(error) &&
      this.showToast
    ) {
      this.showToast({
        tone: 'critical',
        message: 'The session is invalid.',
        description:
          'Please enter your email address and click “Send Email” again.',
      });
      navigationService.goToLogin();
      return Promise.reject(error);
    }

    // ignore if error status is something other than 401
    // ignore if received any errors from /refresh api, otherwise will cause loop
    // ignore if received REFRESH_TOKEN_FAILED error, otherwise will cause loop

    if (
      error.response?.status !== 401 ||
      error.config?.url === '/hirers/refresh' ||
      error.config?.url === '/hirers/auth' ||
      error.message === ErrorMessage.REFRESH_TOKEN_FAILED
    ) {
      return Promise.reject(error);
    }

    try {
      await this.refreshToken();

      // return error as usual, let tanstack usequery retry with new token
      // see: https://tanstack.com/query/v4/docs/react/guides/query-retries
      return Promise.reject(error);
    } catch {
      // let tanstack usequery handle this specific error
      return Promise.reject(new Error(ErrorMessage.REFRESH_TOKEN_FAILED));
    }
  }

  async checkAuth(token?: string): Promise<void> {
    if (token) {
      const { data } = await this.client.get<
        Response<{
          accessToken: string;
          refreshToken: string;
        }>
      >('/hirers/auth', {
        params: {
          token,
        },
        headers: {
          'x-request-id': v4(),
        },
      });

      if (data?.data) {
        localStorage.setItem(
          LocalStorageKey.ACCESS_TOKEN,
          data.data.accessToken,
        );
        localStorage.setItem(
          LocalStorageKey.REFRESH_TOKEN,
          data.data.refreshToken,
        );
        dispatchEvent(new Event('storage'));
      }
    }

    const accessToken = localStorage.getItem(LocalStorageKey.ACCESS_TOKEN);
    const refreshToken = localStorage.getItem(LocalStorageKey.REFRESH_TOKEN);

    if (!accessToken || !refreshToken) {
      throw new Error('Missing access or refresh token');
    }

    this.setAuthHeader(accessToken);
  }

  async authorizeWithCompanyId(companyId: number): Promise<void> {
    const { data } = await this.client.get<
      Response<{
        accessToken: string;
        refreshToken: string;
      }>
    >('/hirers/authorize', {
      params: {
        refreshToken: localStorage.getItem(LocalStorageKey.REFRESH_TOKEN) ?? '',
        companyId,
      },
      headers: {
        'x-request-id': v4(),
      },
    });

    if (data?.data) {
      localStorage.setItem(LocalStorageKey.ACCESS_TOKEN, data.data.accessToken);
      localStorage.setItem(
        LocalStorageKey.REFRESH_TOKEN,
        data.data.refreshToken,
      );
      dispatchEvent(new Event('storage'));
    }

    const accessToken = localStorage.getItem(LocalStorageKey.ACCESS_TOKEN);
    const refreshToken = localStorage.getItem(LocalStorageKey.REFRESH_TOKEN);

    if (!accessToken || !refreshToken) {
      throw new Error('Missing access or refresh token');
    }

    this.setAuthHeader(accessToken);
  }

  async logout(): Promise<void> {
    const refreshToken = localStorage.getItem(LocalStorageKey.REFRESH_TOKEN);
    if (refreshToken) {
      try {
        await this.client.get('/hirers/logout', {
          params: {
            refreshToken,
          },
          headers: {
            'x-request-id': v4(),
          },
        });
      } catch {
        // todo: log error here
      }
    }

    this.clearAuth();
  }

  async send<T, D = object>(
    requestConfig: AxiosRequestConfig<D>,
  ): Promise<Response<T>> {
    const { data } = await this.client.request<Response<T>>({
      ...requestConfig,
      headers: {
        ...requestConfig.headers,
        'x-request-id': v4(),
      },
    });

    return {
      success: data.success,
      data: data.data,
      meta: data?.meta,
    };
  }

  async download(endpoint: string): Promise<AxiosResponse<Blob>> {
    const response = await this.client.request({
      url: endpoint,
      responseType: 'blob',
      headers: {
        'x-request-id': v4(),
      },
    });

    return response;
  }
}
