import { Box, Stack, Text } from 'braid-design-system';
import type { ReactNode } from 'react';

interface TableBodyStateProps {
  SVGComponent?: ReactNode;
  title?: string;
  subtitle?: JSX.Element;
  action?: JSX.Element;
}
export const TableBodyState = ({
  SVGComponent,
  title,
  subtitle,
  action,
}: TableBodyStateProps) => (
  <Box
    width="full"
    height="full"
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    marginY="xxxlarge"
  >
    {SVGComponent}
    <Stack space="xsmall" align="center">
      <Text size="large" weight="strong">
        {title}
      </Text>

      <Stack space="small">
        {subtitle}
        {action}
      </Stack>
    </Stack>
  </Box>
);
