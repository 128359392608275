/**
 * Utils related to objects
 */

/**
 * Flattens nested objects
 *
 * Ex: { hirer: { name: 'A' } } into { 'hirer.name': 'A' }
 */
export const flattenObject = (
  obj: Record<string, any>,
  key?: string,
): Record<string, any> => {
  let flattenedObject: Record<string, any> = {};
  for (const property in obj) {
    if (typeof obj[property] === 'object') {
      flattenedObject = {
        ...flattenedObject,
        ...flattenObject(obj[property], `${key ? `${key}.` : ''}${property}`),
      };
    } else {
      flattenedObject[`${key ? `${key}.` : ''}${property}`] = obj[property];
    }
  }

  return flattenedObject;
};
