import { datadogRum } from '@datadog/browser-rum';

interface DataDogConfig {
  buildVersion: string;
  environment: string;
}

export const loadDatadogRum = ({
  buildVersion,
  environment,
}: DataDogConfig) => {
  datadogRum.init({
    applicationId: '9535e1cc-0f63-48f3-9d37-11571174b2b5',
    clientToken: 'pub5608a07108187ed57b030f816d60675e',
    site: 'datadoghq.com',
    service: 'smart-hire-hirer-ui',
    env: environment,
    version: `${buildVersion}`,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    beforeSend(event) {
      event.view.url = event.view.url.replace(/token=[^&]*/, 'token=REDACTED');
      event.view.url = event.view.url.replace(
        /refreshToken=[^&]*/,
        'refreshToken=REDACTED',
      );

      if (event.view.referrer) {
        event.view.referrer = event.view.referrer.replace(
          /token=[^&]*/,
          'token=REDACTED',
        );
        event.view.referrer = event.view.referrer.replace(
          /refreshToken=[^&]*/,
          'refreshToken=REDACTED',
        );
      }

      if (event.type === 'resource') {
        event.resource.url = event.resource.url.replace(
          /token=[^&]*/,
          'token=REDACTED',
        );
        event.resource.url = event.resource.url.replace(
          /refreshToken=[^&]*/,
          'refreshToken=REDACTED',
        );
      }
      return true;
    },
  });

  datadogRum.startSessionReplayRecording();
};
