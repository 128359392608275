import { useQuery } from '@tanstack/react-query';

import { QueryKey } from 'src/constants/queryKeys';
import type { User } from 'src/contexts/UserContex/UserContextProvider';
import { useCheckIsLoggedIn } from 'src/hooks/useCheckIsLoggedIn/useCheckIsLoggedIn';

import { useSmartHireClient } from '../useSmartHireClient/useSmartHireClient';

export const useGetCurrentHirer = () => {
  const { smartHireClient } = useSmartHireClient();
  const isLoggedIn = useCheckIsLoggedIn();

  const fetchCurrentHirer = async (): Promise<User> => {
    try {
      const { data } = await smartHireClient.send<User>({
        url: `/hirers/me`,
      });
      if (data) {
        return data;
      }
      throw new Error(`Unable to fetch current user`);
    } catch (error) {
      throw error;
    }
  };

  return useQuery<User, Error>({
    queryKey: [QueryKey.CURRENT_HIRER],
    queryFn: fetchCurrentHirer,
    enabled: isLoggedIn,
    retry: 2,
  });
};
