import { createContext } from 'react';

import type { ClientContext } from 'src/types/types';

export interface AppConfigContextType {
  environment: ClientContext['environment'];
}

export const AppConfigContext = createContext<AppConfigContextType | null>(
  null,
);

export const AppConfigContextProvider = AppConfigContext.Provider;
