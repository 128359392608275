import { useContext } from 'react';

import {
  AppConfigContext,
  type AppConfigContextType,
} from 'src/contexts/AppConfig/AppConfigProvider';

export const useAppConfig = (): AppConfigContextType => {
  const context = useContext(AppConfigContext);

  if (context === null) {
    throw new Error(`useAppConfig must be used within a AppConfigProvider`);
  }

  return context;
};
